'use strict';

angular.module('cpformplastApp.jobs')
    .factory('Job', function(Salesman, Item, Util) {

    class Job {
        constructor({id, number, po, poDate, unitPrice, billingAddress, shippingAddress, archive, salesman, machineType, item, secondItem, thirdItem, materialDeliveryDate}) {
          this.id = id;
          this.number = Number.parseInt(number, 10);
          this.po = po;
          this.poDate = poDate ? new Date(poDate) : "";
          this.unitPrice = unitPrice;
          this.billingAddress = billingAddress;
          this.shippingAddress = shippingAddress;
          this.archive = archive;
          this.machineType = machineType;
          this.salesman = salesman ? new Salesman(salesman) : undefined;
          this.item = new Item(item);
          this.secondItem = secondItem ? new Item(secondItem) : undefined;
          this.thirdItem = thirdItem ? new Item(thirdItem) : undefined;
          this.materialDeliveryDate = materialDeliveryDate ? new Date(materialDeliveryDate) : undefined;
        }
    }

    return Job;
});
